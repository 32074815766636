@font-face {
  font-family: 'Univers Next W05 Light';
  src: url('/fonts/5656268/54a7f9d3-6607-4c69-818e-c24ea246d4b9.woff2')
      format('woff2'),
    url('/fonts/5656268/6c20c388-7de7-41a7-94dc-9b9644811fec.woff')
      format('woff');
}

@font-face {
  font-family: 'Univers Next W05 Regular';
  src: url('/fonts/5656275/5dac8626-172e-4f72-98df-8667df989493.woff2')
      format('woff2'),
    url('/fonts/5656275/03385478-0a27-40a7-b628-f241b112274a.woff')
      format('woff');
}

@font-face {
  font-family: 'Tiny Variable';
  src: url('/fonts/TINY5x3GX.ttf') format('truetype');
}
