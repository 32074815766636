.react-time-picker {
  display: inline-flex;
  position: relative;
}

.react-time-picker--active > div.react-time-picker__wrapper {
  border-color: var(--colors-strokeFocus);
}

.react-time-picker--disabled {
  border-color: var(--colors-strokeSecondary);
  color: var(--colors-fgHigh);
}

.react-time-picker__inputGroup__input:disabled {
  color: var(--colors-fgHigh);
  opacity: 1;
}

.react-time-picker__inputGroup__input:disabled
  ~ .react-time-picker__inputGroup__divider {
  color: var(--colors-fgHigh);
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

  /* Custom styles */
  height: var(--sizes-controlHeight);
  border: 1px solid;
  border-color: var(--colors-strokePrimary);
  border-radius: var(--radii-S);
  padding-left: var(--space-paddingUIXL);
  padding-right: var(--space-paddingUIXL);

  font-size: var(--fontSizes-base);
  line-height: var(--lineHeights-3);
  color: var(--colors-fgHigh);
  font-family: inherit;
}

.react-time-picker__wrapper:focus-within {
  border-color: var(--colors-strokeFocus);
}

.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0;
  box-sizing: content-box;
}

.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
  display: inline-block;
}

.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  /* Custom styles */
  outline: none;
}

.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-time-picker__inputGroup__input:invalid {
  background: var(--colors-fgMedDanger);
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-time-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* .react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
} */

/* .react-time-picker__button:enabled {
  cursor: pointer;
} */

/* .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
} */

/* .react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
} */

/* .react-time-picker__button svg {
  display: inherit;
} */

/* .react-time-picker__clock {
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  z-index: 1;
}

.react-time-picker__clock--closed {
  display: none;
} */
